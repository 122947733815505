/* eslint-disable */
import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles,
  ListItem,
  TextField
} from '@material-ui/core';
import clsx from 'clsx';
import NavItem from './NavItem';
import Niftron from "src/baas/config";
import BarChartIcon from '@material-ui/icons/BarChart';
import PeopleIcon from '@material-ui/icons/People';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
// import ReceiptIcon from '@material-ui/icons/Receipt';
// import StorageIcon from '@material-ui/icons/Storage';
// import VpnKeyIcon from '@material-ui/icons/VpnKey';
// import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
// import PublishIcon from '@material-ui/icons/Publish';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LockIcon from '@material-ui/icons/Lock';
import store from "src/redux/reducers"
import { ADD_PROJECT_KEY, ADD_DEMO_STEPS } from 'src/redux/constants/ActionTypes'
import { useSelector } from 'react-redux'
import DescriptionIcon from '@material-ui/icons/Description';
import { useNavigate } from "react-router"
import {
  // createProjectDemo,
  LayoutIntroDemo,
  GettingStartedDemo,
  AuthenticationDemo,
  TokenizationDemo
} from "src/demos/Demo"
const items = [
  {
    href: '/dashboard',
    icon: BarChartIcon,
    title: 'Dashboard',
    id: "dashboard_nav_demo",
  },
  {
    href: '/authentication',
    icon: PeopleIcon,
    title: 'Authentication',
    id: "authentication_nav_demo",
  },
  {
    href: '/tokenization',
    icon: ConfirmationNumberIcon,
    title: 'Tokenization',
    id: "tokenization_nav_demo",
  },
  {
    href: '/projectDetails',
    icon: DescriptionIcon,
    title: 'Project Details',
    id: "projectDetails_nav_demo",
  },
  // {
  //   href: '/transactions',
  //   icon: ReceiptIcon,
  //   title: 'Transactions',
  //   id: "transactions_nav_demo",
  // },
  // {
  //   href: '/data',
  //   icon: StorageIcon,
  //   title: 'Data',
  // id: "#dashboard_nav_demo",
  // },
  // {
  //   href: '/consents',
  //   icon: VpnKeyIcon,
  //   title: 'Consents',
  // id: "#dashboard_nav_demo",
  // },
  // {
  //   href: '/approvals',
  //   icon: CheckCircleOutlineIcon,
  //   title: 'Approvals',
  // id: "#dashboard_nav_demo",
  // },
  {
    href: '/credentials',
    icon: LockIcon,
    title: 'Credentials',
    id: "credentials_nav_demo",
  }
];

const secondaryItems = [
  {
    href: '/credits',
    icon: MonetizationOnIcon,
    title: 'My Credits',
    id: "credits_nav_demo",
  },
  // {
  //   href: '/activate',
  //   icon: PublishIcon,
  //   title: 'Activate',
  //   id: "activate_nav_demo",
  // },
  {
    href: '/settings',
    icon: SettingsIcon,
    title: 'Settings',
    id: "settings_nav_demo",
  },
];

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%'
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  title: {
    marginRight: 'auto'
  },
  active: {
    color: theme.palette.primary.main,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '& $icon': {
      color: theme.palette.primary.main
    }
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();

  const [userData, setUserData] = React.useState(null)
  const project = useSelector(state => state.ProjectKey)
  const projects = useSelector(state => state.Projects)

  const logout = (e) => {
    e.preventDefault()
    localStorage.removeItem("secretKey")
    localStorage.removeItem("niftoken");
    Niftron.user.authRedirect({
      redirectUrl: window.location.href,
      // warning: "",
      userType: "2"
    }, true)
  }

  const handleProjectChange = (e) => {
    e.preventDefault()

    if (e.target.value === "CREATEPROJECT") {
      navigate("/project/create")
    }

    store.dispatch({ type: ADD_PROJECT_KEY, text: e.target.value })

    // if (projects.length > 0) {
    //   projects.forEach((proj, i) => {
    //     if (proj.projectAccount.publicKey === e.target.value) {
    //       if (projects[i].isOnboarding && projects[i].onBoardingLevel) {
    //         switch (projects[i].onBoardingLevel) {
    //           case 0: store.dispatch({ type: ADD_DEMO_STEPS, text: LayoutIntroDemo }); break;
    //           case 10: store.dispatch({ type: ADD_DEMO_STEPS, text: GettingStartedDemo }); break;
    //           case 20: store.dispatch({ type: ADD_DEMO_STEPS, text: AuthenticationDemo }); break;
    //           case 40: store.dispatch({ type: ADD_DEMO_STEPS, text: TokenizationDemo }); break;
    //           default: store.dispatch({ type: ADD_DEMO_STEPS, text: LayoutIntroDemo })
    //         }
    //       }
    //     }
    //   });
    // }
  }

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  React.useEffect(() => {
    const fetchData = async () => {
      if (localStorage.getItem("account")) {
        setUserData(JSON.parse(localStorage.getItem("account")))
      }
    }
    fetchData()
  }, []);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <TextField
        fullWidth
        // label="Select Account Type"
        name="userType"
        onChange={handleProjectChange}
        // required
        select
        SelectProps={{ native: true }}
        value={project}
        variant="outlined"
      >
        {projects.map((option) => (
          <option
            key={option.projectAccount.publicKey}
            value={option.projectAccount.publicKey}
          >
            {option.name}
          </option>
        ))}
        <option
          key={"createProject"}
          value={"CREATEPROJECT"}
        >
          {"Create New Project +"}
        </option>
      </TextField>
      {userData && <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        p={2}
      >
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          src={userData.userImage}
          to="/account"
        />
        <Typography
          className={classes.name}
          color="textPrimary"
          variant="h5"
        >
          {userData.userName}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body2"
        >
          {userData.mobileNumber}
        </Typography>
      </Box>
      }
      <Divider />
      <Box p={2}>
        <List>
          {/* <ListItem
            className={clsx(classes.item)}

            disableGutters
          >
            <Typography
              className={classes.name}
              color="textPrimary"
              variant="h5"
            >
              SERVICES
            </Typography>
          </ListItem> */}
          {items.map((item) => (
            <NavItem
              id={item.id}
              href={item.href}
              key={item.id}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
      <Box flexGrow={1} />
      <Divider />
      <Box p={2} >
        <List>
          <ListItem
            key="doc_nav_demo"

            className={clsx(classes.item)}
            disableGutters
          >
            <Button
              id="doc_nav_demo"
              activeClassName={classes.active}
              className={classes.button}
              href={"https://docs.niftron.com"}
              target={"_blank"}
            >
              <LibraryBooksIcon size="20" className={classes.icon} />
              <span className={classes.title}>
                Documentation
              </span>
            </Button>
          </ListItem>
          {secondaryItems.map((item) => (
            <NavItem
              id={item.id}
              href={item.href}
              key={item.id}
              title={item.title}
              icon={item.icon}
            />
          ))}

          <ListItem
            key="logout_nav_demo"
            className={clsx(classes.item)}
            disableGutters
          >
            <Button
              id="logout_nav_demo"
              activeClassName={classes.active}
              className={classes.button}
              onClick={logout}
            >
              <ExitToAppIcon size="20" className={classes.icon} />
              <span className={classes.title}>
                Logout
              </span>
            </Button>
          </ListItem>
        </List>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => { },
  openMobile: false
};

export default NavBar;
