/* eslint-disable */
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles
} from '@material-ui/core';
// import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
// import MoneyIcon from '@material-ui/icons/Money';
import { getNiftronCreditBalance } from "src/services/userManagement"
const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  avatar: {
    // backgroundColor: colors.orange[600],
    height: 56,
    width: 56
  },
  differenceIcon: {
    color: colors.orange[900]
  },
  differenceValue: {
    color: colors.orange[900],
    marginRight: theme.spacing(1)
  }
}));

const Credits = ({ className, project, ...rest }) => {
  const classes = useStyles();

  const [value, setValue] = React.useState(0);
  const [creditsCount, setCreditsCount] = React.useState(0);

  const addCredits = (number) => {
    setCreditsCount(creditsCount + number)
  }
  React.useEffect(() => {
    const fetchBalance = async () => {
      for (let index = 0; index < project.contributors.length; index++) {
        const contributor = project.contributors[index];
        const credits = await getNiftronCreditBalance(contributor.publicKey, true)
        if (credits == null) {
          addCredits(0)
        } else {
          addCredits(credits.balance)
        }
      }
    }
    fetchBalance()
  }, []);
  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Grid
          container
          justify="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >REMAINING CREDIT</Typography>
            <Typography
              color="textPrimary"
              variant="h3"
            >
              {creditsCount} Niftrons
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              {/* <b>N</b> */}
              <img src="favicon.png" width="30"></img>
              {/* <MoneyIcon /> */}
            </Avatar>
          </Grid>
        </Grid>
        <Box
          mt={2}
          display="flex"
          alignItems="center"
        >
          {/* <ArrowDownwardIcon className={classes.differenceIcon} /> */}
          {/* <Typography
            className={classes.differenceValue}
            variant="body2"
          >
            12%
          </Typography> */}
          <Typography
            color="textSecondary"
            variant="caption"
          >
            This is the collective credit pool of the contributors
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

Credits.propTypes = {
  className: PropTypes.string
};

export default Credits;
