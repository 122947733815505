// dev
// export var niftronUserLambda = "http://localhost:7000/api/v1";
export var niftronUserLambda = "https://8gq4vhf2j3.execute-api.us-east-1.amazonaws.com/dev";
// export var niftronTokenLambda = "http://localhost:7001/api/v1";
export var niftronTokenLambda = "https://rdty586fta.execute-api.us-east-1.amazonaws.com/dev";
export var NiftronApi = "https://dev-api.niftron.com";
export var StellarUrlTest= "https://horizon-testnet.stellar.org"
export var StellarUrl= "https://horizon.stellar.org"
export var StellarNetwork="TestNet"
export var Version="0.01"
export var NiftronIssuerPublicKey="GBIV5P2STT7M7633B4IU54MUFBKWELUX5J7ELA5QBXUGRXM4MQYKJHE7"
export var NiftronDistributorPublicKey="GCC4PL7FJJ74DGVAU3ODHU4HPU7E6CNQ6XMMV3CKEC4GFB4QG3W4L7ZF"

//prod
// // export var niftronUserLambda = "http://localhost:7000/api/v1";
// export var niftronUserLambda = "https://32o47h3pc2.execute-api.us-east-1.amazonaws.com/prod";
// // export var niftronTokenLambda = "http://localhost:7001/api/v1";
// export var niftronTokenLambda = "https://y548z5pqmi.execute-api.us-east-1.amazonaws.com/prod";
// export var StellarUrlTest= "https://horizon-testnet.stellar.org"
// export var StellarUrl= "https://horizon.stellar.org"
// export var StellarNetwork="TestNet"
// export var Version="0.01"
// export var NiftronIssuerPublicKey="GBIV5P2STT7M7633B4IU54MUFBKWELUX5J7ELA5QBXUGRXM4MQYKJHE7"
// export var NiftronDistributorPublicKey="GCC4PL7FJJ74DGVAU3ODHU4HPU7E6CNQ6XMMV3CKEC4GFB4QG3W4L7ZF"
